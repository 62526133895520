import * as Sentry from "@sentry/browser";
import { AxiosError, AxiosResponse } from "axios";
import { ClinicianApi } from "../types/clinician-api";
import { ConsultReasonForVisitEnum, PaginatedConsultList, Program } from "../types/consult-api";

const handleError = (err: AxiosError | any, consultGuid?: string[]) => {
    let errorMessage = err.response?.data?.message || err.message || "An unexpected error occurred. Please try again later.";
    if (consultGuid && consultGuid.length > 0) {
        errorMessage = "Unavailable Consult";
    }
    throw new Error(errorMessage);
};

type reasonTypes =
    | "initial"
    | "follow_up"
    | "side_effect"
    | "pharmacy_change"
    | "medication_change"
    | "additional_documentation"
    | "adhoc_follow_up"
    | "adhoc_side_effect"
    | "adhoc_pharmacy_change"
    | "adhoc_pharmacy_call"
    | "adhoc_medication_change"
    | "adhoc_additional_documentation"
    | "lab_result";

export async function getMyConsults(
    consultGuid?: string[],
    partnerGuid?: string[],
    programGuid?: string[],
    reasonForVisit?: ConsultReasonForVisitEnum[],
    asyncConsults?: boolean
): Promise<PaginatedConsultList> {
    const client = new ClinicianApi();
    try {
        const reasons = reasonForVisit as reasonTypes[];
        const response = (await client.consult.consultGetAssignedConsultsRetrieve(
            consultGuid,
            asyncConsults,
            partnerGuid,
            programGuid,
            reasons
        )) as AxiosResponse<PaginatedConsultList>;
        return response.data;
    } catch (err: AxiosError | any) {
        Sentry.captureException(err);
        handleError(err, consultGuid);
    }
    return { next: "", previous: "", results: [] };
}

export async function getOtherConsults(
    consultGuid?: string[],
    partnerGuid?: string[],
    programGuid?: string[],
    reasonForVisit?: ConsultReasonForVisitEnum[],
    asyncConsults?: boolean
): Promise<PaginatedConsultList> {
    const client = new ClinicianApi();
    try {
        const reasons = reasonForVisit as reasonTypes[];
        const response = (await client.consult.consultGetAvailableConsultsRetrieve(
            consultGuid,
            asyncConsults,
            partnerGuid,
            programGuid,
            reasons
        )) as AxiosResponse<PaginatedConsultList>;
        return response.data;
    } catch (err: AxiosError | any) {
        Sentry.captureException(err);
        handleError(err, consultGuid);
    }
    return { next: "", previous: "", results: [] };
}

export async function getNextUpConsults(consultGuid?: string[]): Promise<PaginatedConsultList> {
    const client = new ClinicianApi();
    try {
        const response = (await client.consult.consultGetNextUpConsultsRetrieve(consultGuid)) as AxiosResponse<PaginatedConsultList>;
        return response.data;
    } catch (err: AxiosError | any) {
        Sentry.captureException(err);
        handleError(err, consultGuid);
    }
    return { next: "", previous: "", results: [] };
}

export async function getNextUpScheduledConsults(): Promise<PaginatedConsultList> {
    const client = new ClinicianApi();
    try {
        const response = (await client.consult.consultGetNextUpScheduledConsultsRetrieve()) as AxiosResponse<PaginatedConsultList>;
        return response.data;
    } catch (err: AxiosError | any) {
        Sentry.captureException(err);
    }
    return { next: "", previous: "", results: [] };
}

export async function getCompletedConsults(): Promise<PaginatedConsultList> {
    const client = new ClinicianApi();
    try {
        const response = (await client.consult.consultGetCompletedConsultsRetrieve()) as AxiosResponse<PaginatedConsultList>;
        return response.data;
    } catch (err: AxiosError | any) {
        Sentry.captureException(err);
    }
    return { next: "", previous: "", results: [] };
}

export async function getMyPrograms(): Promise<Program[]> {
    const client = new ClinicianApi();
    try {
        const response = (await client.program.programList()) as AxiosResponse<Program[]>;
        return response.data;
    } catch (err: AxiosError | any) {
        Sentry.captureException(err);
    }
    return [];
}
