import moment from "moment";
import { Encounter, Episode } from "../types/emr-api";

const hasNewNotes = (episode: Episode, encounters: Array<Encounter>) => {
    if (!episode) {
        return false;
    }
    return (
        episode.comments.length > 0 &&
        ((encounters.length > 0 && moment(episode.comments[episode.comments.length - 1].createdAt) > moment(encounters[0].completedAt)) ||
            encounters.length == 0)
    );
};

export default hasNewNotes;
