import { JSX } from "preact";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Divider } from "primereact/divider";
import { InputTextarea } from "primereact/inputtextarea";
import { RadioButton } from "primereact/radiobutton";
import React, { useContext, useEffect, useState } from "react";
import { FeatureToggleContext } from "../../context/FeatureToggleContext";
import { isFeatureEnabled } from "../../functions/consult/isFeatureEnabled";
import { FeatureNameEnum } from "../../hooks/useFeatureToggle";
import { Annotation, Reason, ReasonStatusEnum } from "../../types/consult-api";
import { Message, MessageRecipientEnum } from "../../types/emr-api";
import { Assessment } from "./Assessment";
import { NextConsultModal } from "./NextConsultModal";
import { PatientInstructions } from "./PatientInstructions";

const getDialog = function (params: ConsultModalDialogParameters) {
    const { features } = useContext(FeatureToggleContext);
    const isNextConsultModalEnabled = isFeatureEnabled(features, FeatureNameEnum.NextConsultModal);

    if (isNextConsultModalEnabled) {
        return NextConsultModal(params);
    }
    return renderDialog(params);
};

export interface ConsultModalProps {
    isVisible: boolean;
    cancelFn: (props?: any) => void;
    completeFn: (props?: any) => void;
    annotations?: {
        assessment: Annotation[];
        treatmentPlan: Annotation[];
    };
    isMessageConsult?: boolean;
    header?: string;
    content?: string;
    messages?: Message[];
    reasons?: Reason[];
    cancelText?: string;
}

export enum ConsultModalDialogEnum {
    AdminReject,
    CannotProvideCare,
    Complete,
    ReferOut,
    OnHold,
    ApproveLab,
    DenyLabConsult,
    ExternalComplete,
    ExternalCancel,
    Unknown,
}

export interface ConsultModalDialogParameters {
    isVisible: boolean;
    header: string;
    cancel: string;
    cancelFn: () => void;
    complete: string;
    completeFn: () => Promise<boolean> | void;
    isCompleteDisabled?: boolean;
    children?: JSX.Element;
    className?: string;
    completeTooltip?: string;
    draggable?: boolean;
    style?: any;
    type?: ConsultModalDialogEnum;
}

export interface ConsultModalFooterParameters {
    isCompleteDisabled: boolean;
    cancel: string;
    cancelFn: () => any;
    complete: string;
    completeTooltip: string;
    completeFn: () => any;
    continueFn?: () => void;
}

export const renderDialog = (params: ConsultModalDialogParameters) => {
    const isCompleteDisabled = params.isCompleteDisabled || false;
    const style = params.style || { minHeight: "225px", minWidth: "640px", maxWidth: "800px" };
    return (
        <Dialog
            breakpoints={{ "400px": "280px" }}
            className={params.className}
            draggable={params.draggable}
            footer={renderFooter({
                isCompleteDisabled,
                cancel: params.cancel,
                cancelFn: params.cancelFn,
                completeTooltip: params.completeTooltip || "",
                complete: params.complete,
                completeFn: params.completeFn,
            })}
            header={params.header}
            style={style}
            onHide={params.cancelFn}
            visible={params.isVisible}
        >
            {params.children}
        </Dialog>
    );
};

export const renderFooter = (params: ConsultModalFooterParameters) => {
    const [cancelClicked, setCancelClicked] = useState(false);
    const [completeClicked, setCompleteClicked] = useState(false);

    const disableLoading = function () {
        setCancelClicked(false);
        setCompleteClicked(false);
    };

    const evalFn = (fn: () => any) => {
        try {
            const possiblePromise = fn();
            if (possiblePromise.catch) {
                possiblePromise.then(() => disableLoading()).catch(() => disableLoading());
            }
        } catch {
            disableLoading();
        }
    };

    return (
        <>
            {params.cancel && (
                <Button
                    onClick={() => {
                        setCancelClicked(true);
                        evalFn(params.cancelFn);
                    }}
                    disabled={cancelClicked || completeClicked}
                    label={params.cancel}
                    loading={cancelClicked}
                    className="p-button-text programs-btn-txt-off"
                    style={{ color: "#26aebc", fontSize: "14px" }}
                ></Button>
            )}
            <Button
                autoFocus
                onClick={async () => {
                    setCompleteClicked(true);
                    evalFn(params.completeFn);
                }}
                disabled={params.isCompleteDisabled || cancelClicked || completeClicked}
                label={params.complete}
                loading={completeClicked}
                style={{ backgroundColor: "#26aebc", fontSize: "14px" }}
                tooltip={params.completeTooltip}
                tooltipOptions={{ showOnDisabled: true, position: "top" }}
            ></Button>
        </>
    );
};

export function AdminRejectModal({ isVisible, cancelFn, completeFn, reasons }: ConsultModalProps) {
    const header = "Cancel Visit";
    const cancel = "Go Back";
    const complete = "Cancel Visit";
    const [selectedCategory, setSelectedCategory] = useState(null as Reason | null);
    const children = (
        <div>
            <p className={"text-sm font-normal"}>
                Please choose a non-clinical reason for canceling this visit.{" "}
                <span className={"font-semibold"}>This choice could be shared with the patient.</span>
                <span style={{ color: "red" }}>*</span>
            </p>
            {reasons
                ?.filter((reason) => reason.status === ReasonStatusEnum.Rejected)
                .map((reason) => {
                    return (
                        <div key={reason.slug} className="field-radiobutton">
                            <RadioButton
                                name="category"
                                value={reason}
                                onChange={(e) => setSelectedCategory(e.value)}
                                checked={selectedCategory?.guid === reason.guid}
                            />
                            <label className={"text-sm font-normal"}>{reason.text}</label>
                        </div>
                    );
                })}
        </div>
    );
    return getDialog({
        isVisible: isVisible,
        header,
        cancel,
        cancelFn,
        complete,
        completeFn: () => completeFn({ reason: selectedCategory }),
        isCompleteDisabled: !selectedCategory,
        children,
        type: ConsultModalDialogEnum.AdminReject,
    });
}

export function ApproveLabConsultModal({ isVisible, cancelFn, completeFn }: ConsultModalProps) {
    const header = "Approve Lab Order?";
    const cancel = "Cancel";
    const complete = "Approve Lab Order & Complete Consult";
    const children = (
        <span>
            <p className={"text-sm font-normal"}>Approving lab order will complete this consult.</p>
        </span>
    );
    return getDialog({
        isVisible: isVisible,
        header,
        cancel,
        cancelFn,
        complete,
        completeFn,
        children,
        type: ConsultModalDialogEnum.ApproveLab,
    });
}

export function CompleteConsultModal({ isVisible, cancelFn, completeFn }: ConsultModalProps) {
    const style = { minHeight: "225px", width: "600px" };
    const header = "Complete consult?";
    const cancel = "No, continue documenting";
    const complete = "Yes, complete consult";
    const children = (
        <span>
            <p className="text-sm font-normal">Completing the consult saves the encounter information and concludes the consultation.</p>
        </span>
    );
    return getDialog({ isVisible, style, header, cancel, cancelFn, complete, completeFn, children, type: ConsultModalDialogEnum.Complete });
}

export function ExternalCancelConsultModal({ header, content, isVisible, cancelFn, completeFn }: ConsultModalProps) {
    const cancel = "";
    const complete = "Ok";
    const children = (
        <div>
            <p className={"text-sm font-normal"}>{content}</p>
            <p className={"text-sm font-normal"}>Please select another consult from the queue.</p>
        </div>
    );
    return getDialog({ isVisible, header: header || "", cancel, cancelFn, complete, completeFn, children, type: ConsultModalDialogEnum.ExternalCancel });
}

export function ExternalCompleteConsultModal({ header, content, isVisible, cancelFn, completeFn }: ConsultModalProps) {
    const cancel = "";
    const complete = "Ok";
    const children = (
        <div>
            <p className={"text-sm font-normal"}>{content}</p>
            <p className={"text-sm font-normal"}>Please select another consult from the queue.</p>
        </div>
    );
    return getDialog({ isVisible, header: header || "", cancel, cancelFn, complete, completeFn, children, type: ConsultModalDialogEnum.ExternalComplete });
}

export function ExitConsultModal({ isVisible, cancelFn, completeFn }: ConsultModalProps) {
    const header = "Heads up!";
    const cancel = "Cancel";
    const complete = "Exit Consult";
    const children = (
        <span>
            <p className={"text-sm font-normal"}>You’re about to exit out of an incomplete consult. Your progress will not be saved.</p>
            <p className={"text-sm font-normal"}>If you need to unassign yourself from this consult, please contact MedOps.</p>
        </span>
    );
    return renderDialog({
        isVisible,
        header,
        cancel,
        cancelFn,
        complete,
        completeFn,
        children,
    });
}

export function DenyLabConsultModal({ isVisible, cancelFn, completeFn }: ConsultModalProps) {
    const header = "Deny Lab Order";
    const cancel = "Cancel";
    const complete = "Deny Lab Order";
    const [note, setNote] = useState("");
    const completeFnProps = { note } as unknown;
    const children = (
        <div>
            <p className={"text-sm font-normal"}>Please provide a note for denying the lab order. This note could be shared with the patient.*</p>
            <Divider />
            <InputTextarea id="consultNotes" placeholder={"Add Note"} rows={5} className="w-full" value={note} onChange={(e) => setNote(e.target.value)} />
        </div>
    );
    return getDialog({
        isVisible,
        header,
        cancel,
        cancelFn,
        complete,
        completeFn: () => completeFn(completeFnProps),
        children,
        isCompleteDisabled: !note.trim(),
        type: ConsultModalDialogEnum.DenyLabConsult,
    });
}

export function DuplicateConsultModal({ isVisible, cancelFn, completeFn, cancelText }: ConsultModalProps) {
    const style = { minHeight: "225px", width: "600px" };
    const header = "Duplicate consult?";
    const cancel = cancelText ? `${cancelText}` : "Cancel";
    const complete = "Yes, duplicate consult";
    const children = (
        <span>
            <p className="text-sm font-normal">Duplicating the consult allows you to create an addendum for this patient.</p>
        </span>
    );
    return renderDialog({
        isVisible,
        style,
        header,
        cancel,
        cancelFn,
        complete,
        completeFn,
        children,
    });
}

export function OnHoldModal({ isVisible, cancelFn, completeFn }: ConsultModalProps) {
    const header = "";
    const cancel = "";
    const complete = "";
    return NextConsultModal({ isVisible, header, cancel, cancelFn, complete, completeFn, type: ConsultModalDialogEnum.OnHold });
}

export function ReferOutModal({ isVisible, cancelFn, completeFn, isMessageConsult, annotations, messages, reasons }: ConsultModalProps) {
    const header = "Recommend In-Person Care";
    const cancel = "Cancel";
    const complete = "Recommend In-Person Care";
    const hasRepliedThisEncounter: boolean = (messages || []).some((msg: any) => msg.recipient === MessageRecipientEnum.Patient);

    const [assessmentNotesInput, setAssessmentNotesInput] = useState("");
    const [patientInstructionsInput, setPatientInstructionsInput] = useState("");
    const [selectedCategory, setSelectedCategory] = useState(null as Reason | null);

    const children = (
        <div>
            <p className={"text-sm font-normal"}>Please provide information for recommending in-person care.</p>
            <p className="text-base font-semibold mt-4">
                Reason <span style={{ color: "red" }}>*</span>
            </p>
            {reasons
                ?.filter((reason) => reason.status === ReasonStatusEnum.ReferredOut)
                .map((reason) => {
                    return (
                        <div key={reason.slug} className="field-radiobutton">
                            <RadioButton
                                name="category"
                                value={reason}
                                onChange={(e) => setSelectedCategory(e.value)}
                                checked={selectedCategory?.guid === reason.guid}
                            />
                            <label className={"text-sm font-normal"}>{reason.text}</label>
                        </div>
                    );
                })}
            <Assessment value={assessmentNotesInput} onChange={setAssessmentNotesInput} annotations={annotations?.assessment || []} />
            <PatientInstructions value={patientInstructionsInput} onChange={setPatientInstructionsInput} annotations={annotations?.treatmentPlan || []} />
        </div>
    );

    const getCompleteTooltip = function () {
        const errors = [];
        if (!assessmentNotesInput.trim() || !patientInstructionsInput.trim() || !selectedCategory) {
            errors.push("Must enter required fields to complete");
        }
        if (isMessageConsult && !hasRepliedThisEncounter) {
            errors.push("Please reply to message");
        }
        return errors.join(", ");
    };

    const isCompleteDisabled = function () {
        return !assessmentNotesInput.trim() || !patientInstructionsInput.trim() || !selectedCategory || (isMessageConsult && !hasRepliedThisEncounter);
    };

    return getDialog({
        isVisible,
        header,
        cancel,
        cancelFn,
        complete,
        completeTooltip: getCompleteTooltip(),
        completeFn: () => completeFn({ assessmentNotesInput, patientInstructionsInput, reason: selectedCategory }),
        children,
        isCompleteDisabled: isCompleteDisabled(),
        type: ConsultModalDialogEnum.ReferOut,
    });
}

export function RemovePrescriptionModal({ isVisible, cancelFn, completeFn }: ConsultModalProps) {
    const header = "Remove prescription?";
    const cancel = "Cancel";
    const complete = "Yes, remove prescription";
    const children = <div></div>;
    return renderDialog({
        isVisible,
        header,
        cancel,
        cancelFn,
        complete,
        completeFn,
        children,
    });
}

export function CannotProvideCareModal({ isVisible, cancelFn, completeFn, isMessageConsult, annotations, messages, reasons }: ConsultModalProps) {
    const header = "Cancel Visit";
    const cancel = "Go Back";
    const complete = "Cancel Visit";
    const hasRepliedThisEncounter: boolean = (messages || []).some((msg: any) => msg.recipient === MessageRecipientEnum.Patient);

    const [dialogType, setDialogType] = useState(ConsultModalDialogEnum.Unknown);
    const [assessmentNotesInput, setAssessmentNotesInput] = useState("");
    const [patientInstructionsInput, setPatientInstructionsInput] = useState("");
    const [_reason, setReason] = useState(undefined as Reason | undefined);

    const reasonsTemplate = (reasons: Reason[]) => {
        return reasons.map((reason: Reason) => (
            <div key={reason.slug} className="field-radiobutton">
                <RadioButton name="category" value={reason} onChange={(e) => setReason(e.value)} checked={reason.guid === _reason?.guid} />
                <label className={"text-sm font-normal"}>{reason.text}</label>
            </div>
        ));
    };

    useEffect(() => {
        switch (_reason?.status) {
            case ReasonStatusEnum.ReferredOut:
                setDialogType(ConsultModalDialogEnum.ReferOut);
                break;
            case ReasonStatusEnum.Rejected:
                setDialogType(ConsultModalDialogEnum.AdminReject);
                break;
            default:
                setDialogType(ConsultModalDialogEnum.Unknown);
        }
    }, [_reason]);

    const children = (
        <div>
            <p className={"text-sm font-normal"} style={{ marginTop: "0" }}>
                Please choose a reason for why you are cancelling this visit.{" "}
                <span className={"font-semibold"}>This choice could be shared with the patient.</span>
                <span style={{ color: "red" }}>*</span>
            </p>
            <p className="text-sm font-semibold">Clinical Reasons</p>
            {reasonsTemplate((reasons || []).filter((reason) => reason.status === ReasonStatusEnum.ReferredOut))}
            <p className="text-sm font-semibold">Non-Clinical Reasons</p>
            {reasonsTemplate((reasons || []).filter((reason) => reason.status === ReasonStatusEnum.Rejected))}
            <Assessment
                value={assessmentNotesInput}
                onChange={setAssessmentNotesInput}
                annotations={annotations?.assessment || []}
                reason={_reason}
                type={ConsultModalDialogEnum.CannotProvideCare}
            />
            <PatientInstructions
                value={patientInstructionsInput}
                onChange={setPatientInstructionsInput}
                annotations={annotations?.treatmentPlan || []}
                reason={_reason}
                type={ConsultModalDialogEnum.CannotProvideCare}
            />
        </div>
    );

    const getCompleteTooltip = function () {
        const errors = [];
        if (dialogType === ConsultModalDialogEnum.ReferOut) {
            if (!assessmentNotesInput.trim() || !patientInstructionsInput.trim()) {
                errors.push("Must enter required fields to complete");
            }
            if (isMessageConsult && !hasRepliedThisEncounter) {
                errors.push("Please reply to message");
            }
        }
        return errors.join(", ");
    };

    const isCompleteDisabled = function () {
        if (dialogType === ConsultModalDialogEnum.ReferOut) {
            return !assessmentNotesInput.trim() || !patientInstructionsInput.trim() || (isMessageConsult && !hasRepliedThisEncounter);
        }
        return dialogType === ConsultModalDialogEnum.Unknown;
    };

    return getDialog({
        isVisible,
        header,
        cancel,
        cancelFn,
        complete,
        completeTooltip: getCompleteTooltip(),
        completeFn: () => completeFn({ assessmentNotesInput, patientInstructionsInput, reason: _reason }),
        isCompleteDisabled: isCompleteDisabled(),
        children,
        type: ConsultModalDialogEnum.Complete,
    });
}
